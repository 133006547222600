@import "../../stylesheets/common.scss";

.file-upload {
  height: 404px;
  flex: 1;
  margin-top: 8px;
  margin-bottom: 56px;
  flex-direction: column;
  flex-wrap: nowrap;
  // z-index: 1;

  .add-files {
    border-bottom: 2px solid $grey-2;
    padding: 16px 20px; 
    height: 72px;
    .add-files-button {
      height: 40px;
      width: 168px;
      border: solid 2px;
      background-color: white;
      color: $purple-3;
      border-color: $purple-3;
    }

    .add-files-button:hover {
      height: 40px;
      width: 168px;
      background-color: $purple-2;
      color: white;
    }
  }

  .file-input {
    display: none;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    span {
      vertical-align: middle;
    }
  }

  .file-list {
    height: 404px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .file-item {
      border-bottom: 2px solid #cccccc;
      padding-left: 16px;
      height: 46px;
      min-height: 46px;
      font-size: 14px;
      font-weight: 300;
      color: #333333;

      &:last-child {
        border-bottom: none;
      }

      .close-icon {
        border-radius: 50%;
        height: 32px;
        width: 24px;
        min-width: 40px;
        margin-right: 8px;

        .btn-x-remove {
          height: 8px;
          width: 8px;
          color: $purple-3;
        }
      }
      
    }
  }

  .file-drag-area {
    width: 100%;
    display: flex;
    flex: 1;
    align-items: flex-start;
    overflow-y: auto;

    .empty-drop-message {
      height: 100%;
      justify-content: center;
      .text-display {
        color: $grey-3;
      }
    }

    .empty-drop-message-icon {
      background-color: $grey-1;
      color: $grey-3;
      border-radius: 50%;
      display: block;
      height: 160px;
      width: 160px;
      line-height: 160px;
      text-align: center;
      font-size: 70px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
}