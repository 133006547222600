@import "../../stylesheets/common.scss";


.territory {

    .select-chip {
        margin-right: 8px;
        margin-bottom: 8px;
        border: 1px solid $purple-3;
        color: $purple-3;
        font-size: 14px;
        background-color: white;
    }

    .select-chip:hover {
        background-color: $purple-3;
        color: white;
        .clear-sign {
            background-color: $purple-3;
            color: white;
        }
    }

    .select-chip:focus {
        background-color: $purple-3;
        color: white;
        .clear-sign {
            background-color: $purple-3;
            color: white;
        }
    }

    .clear-sign {
        width: 16px;
        color: $purple-3;
        background-color: white;
        border-radius: 50%;
    }

}

.list:hover {
    .territory-list {
        color: $purple-3;
    }
}

.season-data-header {
    font-size: 16px;
    margin-bottom: 32px !important;
    margin-top: 32px !important;
}


.season-pane {
    width: 1280px !important;
    font-size: 14px;
    
    .season-label {
        flex-basis: 160px;
        font-weight: 700;
    }

    .season-text {
        max-width: 60vw;
    }
}

@media screen and (min-width: 1550px) {
    .season-pane {
        width: 90vw !important;
    }
}
