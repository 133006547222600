@import "../stylesheets/common.scss";


.sidecar-layout {

    .next-button {
        background-color: $purple-3;
        bottom: 2px;
        top: 1px;
        height: 40px;
        width: 168px;
    }

    .next-button:disabled {
        background-color: $purple-4;
        span {
            color: white;
        }
        height: 40px;
        width: 168px;
        bottom: 2px;
        top: 1px;
    }
}


.title-modal {
    
    .title-pane-header {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 24px;
    }

    .title-pane-subheading {
        margin-top: 12px;
        flex-basis: 220px;
        text-align: center;
        margin-left: 7px;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 16px;
    }

    .title-pane-language {
        display: flex;
        align-items: center;
        margin-left: -12px;
        margin-top: -16px;
    }

    .title-pane {
        width: 100%;
        font-size: 14px;
        background-color: #ffffff;

        .title-pane-content {
            padding-left: 16px;
            padding-top: 16px;
            padding-bottom: 16px;
            min-height: 312px;
            max-height: 352px;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .item-label {
            flex-basis: 160px;
            font-weight: bolder;
        }

        .name, .runtime, .rating, .actors, .synopsis, .genres {
            margin: 0;
        }

        .list-wrapper {
            max-width: 400px;
        }

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .no-data-found {
            color: #f39c0f;
        }

        .season-data-header {
            font-size: 20px;
            font-weight: 700;
            text-align: center;
            position: relative;
            width: 100%;
            margin-bottom: 32px;
            margin-top: 32px;
        }
    }
}
