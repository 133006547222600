@import "../stylesheets/colors.scss";

.client-list-item, .header-item {
  padding: 8px;

  .client-name {
    padding: 8px;
  }

  button.sidecar-button {
    padding: 0 24px;
    margin: 8px;
  }
}

hr.divider {
  background-color: $grey-2;
}

.client-list-item {
  &:hover {
    background-color: rgba($color: $purple-3, $alpha: 0.16);
  }
}

.client-list-item.header-item {
  h5 {
    letter-spacing: -.5px;
    font-weight: 600;
  }
}

.title-modal {
  
  .title-pane {
    width: 100%;
    font-size: 14px;
    background-color: #ffffff;

    .title-pane-content {
        padding-left: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
        min-height: 312px;
        max-height: 352px;
        overflow-y: auto;
        overflow-x: hidden;
    }
  }
}

.footer {
  .previous-button {
      border: solid 2px;
      background-color: white;
      color: $purple-3;
      border-color: $purple-3;
      bottom: 4px;
      height: 40px;
      width: 168px;
  }

  .previous-button:hover {
      background-color: $purple-2;
      color: white;
  }

  .submit-button {
      background-color: $purple-3;
      bottom: 4px;
      height: 40px;
      width: 168px;
  }

  .submit-button.disabled:hover, .disabled  {
      background-color: $purple-4 !important;
      span {
          color: white;
      }
  }
}