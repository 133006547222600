@import "./mixin.scss";
/*colors*/
@import "./colors.scss";

* {
  margin: 0;
  padding: 0;
}

;

-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-style: italic;
}

-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
}

-ms-input-placeholder {
  /* IE 10+ */
  font-style: italic;
}

-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
}

-webkit-autofill {
  -webkit-box-shadow: 0 0 0 10000px rgb(42, 54, 63) inset;
  -webkit-text-fill-color: white;
}

input {

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
}

.sidecar-layout {
  height: 100%;
  width: 100%;

  footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    height: 64px;
    padding: 10px 32px;
    border-top: 1px solid #757575;
  }

  .no-data-found {
    color: #f39c0f;
  }

  .loading {
    background-color: $purple-3;
  }

  .content {
    &.download {
      height: 85%;
    }

    margin-top: 48px;
    background-color: $grey-1;
    padding: 32px;
    min-height: calc(100vh - 64px);
  }

  .card-padding {
    padding: 24px 24px;
    margin-bottom: 24px;
  }

  // Below style hides the spin button which appears when input type is number.
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    font-size: 14px;
  }

  //Note: This is a workaround for Material-UI select tag endadorment css transform.
  div[aria-pressed="true"]~svg {
    transform: rotate(180deg);
  }

  $primary-color: white;

  // @import 'loaders.css/src/animations/line-spin-fade-loader.scss';
  .loader-hidden {
    display: none;
  }

  .loader-active {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    color: $primary-color;
    transform: scale(0.4);
    background-color: $purple-3;
  }
}

.disabled-item {
  background-color: rgba(0, 0, 0, 0.12);
}

.content {
  .sidecar-button {
    border: solid 2px;
    background-color: white;
    color: $purple-3;
    border-color: $purple-3;
    width: 168px;
    height: 40px;
  }

  .sidecar-button:disabled {
    border: solid 2px;
    background-color: white;
    color: $purple-4;
    border-color: $purple-4;
  }

  .sidecar-button:hover {
    background-color: $purple-2;
    color: white;
  }

  .sidecar-link-button {
    color: $purple-3;
  }
}