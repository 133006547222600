@import '../../stylesheets/colors.scss';

svg.drag-handle  {
  margin-top: 24px;
  margin-right: 16px;
}

.item-normal {
  border-top: none;

  border-top: 1px solid $grey-2;
  border-bottom: 1px solid $grey-2;
}

.item-hover {
  border-bottom: 1px solid $purple-2;
  border-top: 1px solid $purple-2;
}

.item-normal:first-child {
  border-top: none;
}