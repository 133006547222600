@import "../stylesheets/common.scss";


.login-box {
  height: 100%;
  width: 400px;
  display: flex;
  align-items: center;
  margin-left: 140px;

  input, .login-btn  {
    font-family: lato;
    letter-spacing: .5px;
  }

  form {
    height: 214px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .logo {
    align-self: center;
    width: 175px;
    height: 40px;
  }

  .inner {
    height: 328px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .forget-password {
    align-self: flex-end;
  }

  .login-button {
    width: 168px;
    height: 40px;
    align-self: center;
    background-color: $purple-3; 
  }

  .login-button:disabled {
    background-color: $purple-4;
    width: 168px;
    height: 40px;
  }

  .info-display {
    height: 48px;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;

    &.error-display {
      border: 1px solid $error-red;
      background-color: $error-red;
      color: white;
      a {
        color: white;
      }
    }

    &.success-display {
      border: 1px solid $purple-1;
      color: $purple-1;
    }

    .alert-message {
      padding-left: 10px;
      font-family: lato;
    }
  }
}

@media screen and (min-width: 1440px) {
  .login-wrapper {
    height: 100vh;
    background-image: url("../images/logo_gradient.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: fixed;
    background-position-x: 820px;
  }

  .login-box {
    height: 100%;
    width: 400px;
    display: flex;
    align-items: center;
    margin-left: 240px;
  }
}

@media screen and (max-width: 1439px) {
  .login-wrapper {
    height: 100vh;
    background-image: url("../images/logo_gradient.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position-x: 420px;
  }

  .login-box {
    height: 100%;
    width: 400px;
    display: flex;
    align-items: center;
    margin-left: 80px;
  }
}