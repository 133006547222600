@import "../../stylesheets/common.scss";

#sidecar-nav {
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // justify-content: space-between;
    background-color: $purple-1;
    height: 48px;
    padding: 10px 32px;

    .logo {
      height: 32px;
      width: 128px;
    }

    .sidecar-link {
      display: inline-block;
      padding: 8px;
      font-weight: 100;
      margin-top: -4px;
    }

    .settings-icon {
      color: white;
      padding: 0 8px;
      margin-top: -4px;
    }

    .settings-menu {
      background-color: white !important;
    }
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.settings-menu:first-child {
  background-color: white !important;
}

.settings-menu:hover {
  background-color: $purple-4 !important;
}


