@import "../../stylesheets/common.scss";

.client-data {
  min-height: 204px;
  height: auto;

  .client-data-wrapper {
    height: 100%;
  }

  .section-title {
    font-weight: 900;
    font-size: 16px;
    margin-left: 12px;
    margin-top: 8px;
  }

  .client-label, .title-label {
    margin-bottom: 16px;
  }

  .client-selection, .title-search {
    flex: 0.15 auto;
  }

  .client-selection {
    .form-control {
      width: 384px;

      div[aria-pressed="true"] ~ svg {
        transform: rotate(180deg);
      }
    }
  }

  .client-data-label {
    color: $grey-4;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: .25px;
  }
}

.title-suggestion-menu {
  overflow-y: scroll;
  height: 234px;
  background: white;
  position: relative;
  z-index: 2;
  width: calc(100vw - 332px);
}

.title-suggestion-menu .section-container {
  .section-container {
    padding: 0 15px;

    .section-items {
      padding: 0 15px;
    }
  }
}

.title-search {

  .title-details {
    position: relative;
  }

  .title-details-button {
    border: solid 2px;
    background-color: white;
    color: $purple-3;
    border-color: $purple-3;
    width: 168px;
    height: 40px;
  }

  .title-details-button:disabled {
    border: solid 2px;
    background-color: white;
    color: $purple-4;
    border-color: $purple-4;
  }

  .title-details-button:hover {
    background-color: $purple-2;
    color: white;
  }

  .linear-progress {
    height: 2px;
  }
}

.title-menu-item {
  height: 36px;
  background-color: $white;
  cursor: pointer;
  .title-cell {
    border-bottom: none;
    font-size: 14px;

    &.title-header {
      min-width: 40%;
    }

    &.alphaName {
      width: 40%;
    }

    &.alphaIcon {
      width: 16px;
    }
  }
}

.title-menu-item:hover {
  background-color: $purple-4;
}

.client-names {
  background-color: white !important;
}

.client-names:hover {
  background-color: $purple-4 !important;
}

@media screen and (min-width: 1250px) {
  .title-search {
    .title-menu-item {
      .title-cell {
        &.title-header {
          min-width: 28%;
        }
      }
    }
  }
}

@media screen and (min-width: 1550px) {
  .title-search {
    .title-menu-item {
      .title-cell {
        &.title-header {
          min-width: 20%;
        }
      }
    }
  }
}

@media screen and (min-width: 1700px) {
  .title-search {
    .title-menu-item {
      .title-cell {
        &.title-header {
          min-width: 20%;
        }
      }
    }
  }
}