$purple-1: #0e0ea1;
$purple-2: #4f2bf9;
$purple-3: #5e5edb;
$purple-4: #aeaeed;
$inactive-grey: #b5b5b5;
$error-red: #c8003c;
$success-green: #378400;

$white: #ffffff;
$grey-1: #f5f5f5;
$grey-2: #cccccc;
$grey-3: #757575;
$grey-4: #333333;