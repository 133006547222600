@import "../stylesheets/common.scss";


.package-data {
    margin-bottom: 40px;
}

.provider-data {
    padding-right: 24px;
}

#close-caption {
    content: '*'
}

.panel {
    width: 100%;
    margin-bottom: 24px !important;
    margin-top: 24px !important;
}


.data-pane, .title-pane, .season-pane {
    width: 800px;
    font-size: 14px;

    .title-pane-header {
        font-size: 16px;
        margin-bottom: 16px;
    }

    .title-pane-subheading {
        margin-top: 16px;
        font-size: 14px;
        font-weight: 700;
    }

    .item-label {
        flex-basis: 120px;
        font-weight: 700;
    }

    .item-label-stretch {
        flex-basis: 160px;
        font-weight: 700;
    }

    .list-wrapper {
        max-width: 600px;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .no-data-found {
        color: #f39c0f;
    }
}

.footer {
    .previous-button {
        border: solid 2px;
        background-color: white;
        color: $purple-3;
        border-color: $purple-3;
        bottom: 2px;
        top: 1px;
        height: 40px;
        width: 168px;
    }

    .previous-button:hover {
        background-color: $purple-2;
        color: white;
    }

    .submit-button {
        background-color: $purple-3;
        bottom: 2px;
        top: 1px;
        height: 40px;
        width: 168px;
    }

    .with-purple-color {
        color: $purple-4;
    }

    .submit-button.disabled:hover, .disabled  {
        background-color: $purple-4;
        span {
            color: white;
        }
    }
}


.normal {
    border-left: 8px solid white;
}

.normal:focus-within {
    border-left: 8px solid $purple-2;
}

.red-stripe {
    border-left: 8px solid $error-red;
}

.warning {
    border-left: 8px solid #f39c0f;
}


.season-data-header {
    font-size: 16px;
    margin-bottom: 16px;
    margin-top: 16px;
}

.season-label {
    flex-basis: 160px;
    font-weight: 700;
}

.season-text {
    max-width: 72vw;
}



@media screen and (max-width: 1140px) {
    .data-pane {
        .date-picker {
            width: 180px;
        }
    }
}