@import "../../stylesheets/colors.scss";

.title-suggestion-menu {
    overflow-y: scroll;
    height: 234px;
    background: white;
    position: relative;
    z-index: 2;
}

section {
    .linear-progress {
        height: 2px;
    }

    .title-label {
        margin-bottom: 16px;
        margin-top: 16px;

        .client-data-label {
            color: $grey-4;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: .25px;
          }
    }
}

.title-suggestion-menu .section-container {
    .section-container {
        padding: 0 15px;

        .section-items {
        padding: 0 15px;
        }
    }
}

.title-search {

    
    .btn-x-remove {
        width: 8px;
        height: 8px;
    }
    

    .title-details {
        position: relative;
    }

    .title-details-button {
        border: solid 2px;
        background-color: white;
        color: $purple-3;
        border-color: $purple-3;
        width: 160px;
        height: 40px;
        margin-left: 16px;
    }

    .title-details-button:disabled {
        border: solid 2px;
        background-color: white;
        color: $purple-4;
        border-color: $purple-4;
    }

    .title-details-button:hover {
        background-color: $purple-2;
        color: white;
    }

    .linear-progress {
        height: 2px;
    }
}

.title-menu-item {
    height: 36px;
    background-color: $white;
    cursor: pointer;
    .title-cell {
        border-bottom: none;
        font-size: 14px;

        &.title-header {
        min-width: 40%;
        }

        &.alphaName {
        width: 40%;
        }

        &.alphaIcon {
        width: 16px;
        }
    }
}

.title-menu-item:hover {
    background-color: $purple-4;
}
  