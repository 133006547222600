@import "../../stylesheets/common.scss";

#message-id {
    color: white;

    .icon {
        position: relative;
        top: 2px;
    }
    .snackbar-msg {
        color: white;
        margin-left: 10px;
        margin-bottom: 2px;
    }
}