@import "../stylesheets/common.scss";



.download-pane {
  width: 420px;
  padding-bottom: 28px !important;
  h3 {
    font-weight: 700;
  }

  .download-header {
    padding: 24px 24px 12px 24px;
  }

  .download-content {
    padding: 0 24px;
    margin-bottom: 24px
  }

  .alpha {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: max-content;
  }

  .alpha-extended {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
  }

  .label-extended {
    color: #5E5EDB !important;
    position: relative;
    top: 12px;
    text-align: left;
    width: 320px;
  }

  .label {
    color: #5E5EDB !important;
  }

  h6 {
    margin-top: 16px;
    margin-bottom: 16px;
    font-weight: 700;
  }

  .download-manifest-button {
    padding-left: 40px;
    padding-right: 40px;
    height: 40px;
    width: 288px;
  }
}

footer {
  .previous-button {
    height: 40px;
    width: 168px;
    margin-right: 10px;
    bottom: 2px;
    top: 1px;
    border: solid 2px;
    background-color: white;
    color: $purple-3;
    border-color: $purple-3;
  }

  .previous-button:hover {
    background-color: $purple-2;
    color: white;
    margin-right: 10px;
    bottom: 2px;
    top: 1px;
    height: 40px;
    width: 168px;
  }

  .done-button {
    height: 40px;
    width: 168px;
    bottom: 2px;
    top: 1px;
    span {
      color: white;
    }
  }
}

@media screen and ( max-width: 1280px) {
    .download-image {
      background-image: url("../images/Illustration_1024.png");
      height: 100vh;
      background-repeat: no-repeat;
      background-size: contain;
      width: 500px;
      height: 300px;
      margin-left: 24px;
    }
}

@media screen and ( min-width: 1281px ) and ( max-width: 1438px ) {
  .download-image {
    background-image: url("../images/Illustration_1024.png");
    height: 100vh;
    background-repeat: no-repeat;
    background-size: contain;
    width: 60vw;
    height: 72vh;
    margin-left: 24px;
  }
}
 
@media screen and ( min-width: 1440px) {
    .download-image {
      background-image: url("../images/Ilustration_1440.png");
      height: 100vh;
      background-repeat: no-repeat;
      background-size: contain;
      width: 60vw;
      height: 72vh;
      margin-left: 64px;
    }
}